.header {
  margin-top: 3%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  color: white;
  font-family: "Nunito Regular";
  font-size: 50px;
  color: #000000d4;
}


@media (max-width: 620px) {
    .header {
        font-size: 20px;
    }
  }