.test {
  display: flex;
  flex-direction: row;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.mobileMessage {
  display: none;
  text-align: center;
  padding: 20px;
  font-size: 20px;
  line-height: 30px;
}

@media (max-width: 767px) {
  .mobileMessage {
    display: flex; /* Показываем сообщение на мобильных устройствах */
    width: 320px;
    margin: 0 auto;
  }

  .test {
    display: none; /* Скрываем основное содержимое */
  }
}
